import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Select } from 'antd'
import type { SelectProps, SelectValue } from 'antd/lib/select'
import { filter, find, map, propEq, union, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  EmployeeSelectDocs,
  EmployeeSelectQuery,
  EmployeeSelectQueryVariables,
  EmployeesQuery,
} from '../../../../graphql/types'
import {
  Role,
} from '../../../../graphql/types'
import CustomSelect from './CustomSelect'

const fragment = gql`
  fragment EmployeeInEmployeeSelect on Employee {
    id
    name
    role
    taskOwner
  }
`

const employeeSelectQuery = gql`
  query EmployeeSelect(
    $query: EmployeesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    employees(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...EmployeeInEmployeeSelect
      }
    }
  }
  ${fragment}
`

export type EmployeeSelectProps = SelectProps<EmployeeSelectDocs> & {
  handleItemSelect?: (selectedItem: EmployeeSelectDocs) => void
  initItem?: EmployeeSelectDocs
  initItems?: EmployeeSelectDocs[]
  query?: EmployeesQuery
}

function EmployeeSelect(props: EmployeeSelectProps) {
  const {
    handleItemSelect,
    initItem,
    initItems,
    query = {},
    ...restProps
  } = props
  const { t } = useTranslation()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    EmployeeSelectQuery,
    EmployeeSelectQueryVariables
  >(employeeSelectQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query,
      page: 1,
      limit: 1000,
      sort: '-updated',
    },
  })
  const fetchedItems = data?.employees ? data.employees.docs : []
  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const selectedItem = find(propEq('id', id), fetchedItems)
      if (selectedItem && handleItemSelect)
        handleItemSelect(selectedItem)
    }
  }

  const items = initItem
    ? union(fetchedItems, [initItem])
    : initItems
      ? union(fetchedItems, initItems)
      : fetchedItems

  if (loading)
    return null

  return (
    <CustomSelect onSelect={handleSelect} {...restProps}>
      {map((role) => {
        // 根據角色分群組且必須要有 employee
        const itemsByRole = filter((x) => {
          return x.role === role
        }, items)

        return (
          itemsByRole.length > 0 && (
            <Select.OptGroup key={role} label={t(`role.${role}`)}>
              {map(
                item => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ),
                itemsByRole,
              )}
            </Select.OptGroup>
          )
        )
      }, values(Role))}
    </CustomSelect>
  )
}

EmployeeSelect.fragments = {
  EmployeeInEmployeeSelect: fragment,
}

export default EmployeeSelect
