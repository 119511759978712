import { Form } from '@ant-design/compatible'
import { useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { InputNumber, Modal, Select } from 'antd'
import type { SelectValue } from 'antd/lib/select'
import { find, map } from 'ramda'
import React, { useState } from 'react'

import { productsQuery } from '../../../graphql/product/query/list'
import type {
  InvoiceProduct,
  Product,
  ProductsQueryQuery,
  ProductsQueryVariables,
} from '../../../graphql/types'
import { OnceButton } from '../common/button'

type IProduct = Omit<Product, '__typename'>
export type SelectedInvoiceProduct = Omit<InvoiceProduct, '__typename' | 'spec'>

const Option = Select.Option

interface Props {
  handleModalClose: () => void
  handleInvoiceProductAdd: (invoiceProduct: SelectedInvoiceProduct) => void
  isModalVisible: boolean
}

export function ProductSelection(props: Props) {
  const { handleModalClose, handleInvoiceProductAdd, isModalVisible } = props

  const [selectedProductNumber, setSelectedProductNumber] = useState(1)
  const [selectedInvoiceProduct, setSelectedInvoiceProduct]
    = useState<Omit<SelectedInvoiceProduct, 'count'>>()

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { refetch } = useQuery<ProductsQueryQuery, ProductsQueryVariables>(
    productsQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      skip: true,
    },
  )

  const [products, setProducts] = useState<IProduct[]>([])

  const handleDropdownVisibleChange = async (open: boolean) => {
    if (open && products.length === 0) {
      const resopnse = await refetch({
        limit: 1000,
      })
      const refetchedProducts = resopnse.data.products
        ? resopnse.data.products.docs
        : []
      setProducts(refetchedProducts)
    }
  }

  const handleConfirmButtonClick = () => {
    if (selectedInvoiceProduct) {
      handleInvoiceProductAdd({
        ...selectedInvoiceProduct,
        count: selectedProductNumber,
      })
    }
    handleModalClose()
  }

  const handleProductSelect = (id: SelectValue) => {
    const selectedProduct = find(product => product.id === id, products)
    const selectedInvoiceProduct = selectedProduct && {
      productId: String(id),
      serialNumber: selectedProduct.serialNumber,
      name: selectedProduct.name,
      spec: selectedProduct.spec,
      price: selectedProduct.price,
      cost: selectedProduct.cost,
    }
    setSelectedInvoiceProduct(selectedInvoiceProduct)
  }

  const handleProductNumberChange = (productNumber?: number) => {
    setSelectedProductNumber(productNumber || 1)
  }

  return (
    <Modal visible={isModalVisible} onCancel={handleModalClose} footer={null}>
      <Form.Item className="form-item">
        <div>品項 :</div>
        <Select
          onDropdownVisibleChange={handleDropdownVisibleChange}
          onSelect={handleProductSelect}
          optionFilterProp="children"
          placeholder="請選擇出貨品項"
          showSearch
          style={{ width: '100%' }}
        >
          {map(
            item => (
              <Option key={item.id} value={item.id}>
                [
                {item.serialNumber}
                ]
                {' '}
                {item.name}
                {' '}
                ($
                {item.price}
                )
              </Option>
            ),
            products,
          )}
        </Select>
      </Form.Item>
      <Form.Item>
        <div>數量 :</div>
        <InputNumber
          defaultValue={1}
          min={1}
          max={500}
          onChange={value =>
            typeof value === 'number' && handleProductNumberChange(value)}
        />
      </Form.Item>
      <OnceButton
        disabled={!selectedInvoiceProduct}
        label="確認"
        onClick={handleConfirmButtonClick}
      />
    </Modal>
  )
}

export default ProductSelection
