import { gql, useQuery } from '@apollo/client'
import { Select } from 'antd'
import type { SelectProps, SelectValue } from 'antd/lib/select'
import type { DocumentNode } from 'graphql'
import { complement, equals, find, isEmpty, map, propEq, union } from 'ramda'
import type { FC } from 'react'
import React, { memo } from 'react'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import type {
  PatientSelectDocs,
  PatientSelectQuery,
  PatientSelectQueryVariables,
  PatientsQuery,
} from '../../../../graphql/types'
import CustomSelect from './CustomSelect'

const fragment = gql`
  fragment PatientInPatientSelect on Patient {
    id
    name
    clinic {
      id
      name
    }
    sales {
      id
      name
      phone
    }
  }
`

const patientSelectQuery = gql`
  query PatientSelect(
    $query: PatientsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patients(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...PatientInPatientSelect
      }
    }
  }
  ${fragment}
`

export type PatientSelectProps = SelectProps<PatientSelectDocs> & {
  handleItemSelect?: (selectedItem: PatientSelectDocs) => void
  initItem?: PatientSelectDocs
  query?: PatientsQuery
}

function PatientSelectWithoutMemo(props: PatientSelectProps) {
  const { handleItemSelect, initItem, query, ...restProps } = props
  const { data, refetch, loading } = useQuery<
    PatientSelectQuery,
    PatientSelectQueryVariables
  >(patientSelectQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      query,
      page: 1,
      limit: 1000,
      sort: '-updated',
    },
  })
  const fetchedItems = data?.patients ? data.patients.docs : []
  const items = initItem ? union(fetchedItems, [initItem]) : fetchedItems
  const isNotEmpty = complement(isEmpty)

  const searchPatients = async (value) => {
    if (isNotEmpty(value)) {
      refetch({
        query: { ...query, name: value },
        limit: 100,
      })
    }
  }

  const searchSub$ = new Subject()
  searchSub$.pipe(debounceTime(400)).subscribe(searchPatients)

  const handleSearch = (value) => {
    searchSub$.next(value)
  }

  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const selectedItem = find(propEq('id', id), fetchedItems)
      if (handleItemSelect && selectedItem)
        handleItemSelect(selectedItem)
    }
  }

  return (
    <CustomSelect
      onSelect={handleSelect}
      onSearch={handleSearch}
      loading={loading}
      dropdownMatchSelectWidth={false}
      {...restProps}
    >
      {map(
        item => (
          <Select.Option value={item.id} key={item.id}>
            {`${item.name} (${item.clinic.name})`}
          </Select.Option>
        ),
        items,
      )}
    </CustomSelect>
  )
}

interface PatientSelectFragmentMap {
  PatientInPatientSelect: DocumentNode
}

const PatientSelect: FC<PatientSelectProps> & {
  fragments?: PatientSelectFragmentMap
} = memo(PatientSelectWithoutMemo, (prevProps, nextProps) =>
  equals(prevProps, nextProps))

PatientSelect.fragments = {
  PatientInPatientSelect: fragment,
}

export default PatientSelect
