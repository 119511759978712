import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Select } from 'antd'
import type { SelectProps, SelectValue } from 'antd/lib/select'
import { find, map, propEq, union } from 'ramda'
import React from 'react'

import type {
  DoctorSelectDocs,
  DoctorSelectQuery,
  DoctorSelectQueryVariables,
  DoctorsQuery,
} from '../../../../graphql/types'
import CustomSelect from './CustomSelect'

const fragment = gql`
  fragment DoctorInDoctorSelect on Doctor {
    id
    name
  }
`

const doctorSelectQuery = gql`
  query DoctorSelect(
    $query: DoctorsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    doctors(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...DoctorInDoctorSelect
      }
    }
  }
  ${fragment}
`

export type DoctorSelectProps = SelectProps<DoctorSelectDocs> & {
  handleItemSelect?: (selectedItem: DoctorSelectDocs) => void
  initItem?: DoctorSelectDocs
  query?: DoctorsQuery
}

function DoctorSelect(props: DoctorSelectProps) {
  const { handleItemSelect, initItem, query = {}, ...restProps } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const result = useQuery<DoctorSelectQuery, DoctorSelectQueryVariables>(
    doctorSelectQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query,
        page: 1,
        limit: 1000,
        sort: '-updated',
      },
    },
  )
  const fetchedItems = result.data?.doctors ? result.data.doctors.docs : []
  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const selectedItem = find(propEq('id', id), fetchedItems)
      if (selectedItem && handleItemSelect)
        handleItemSelect(selectedItem)
    }
  }
  const items = initItem ? union(fetchedItems, [initItem]) : fetchedItems
  return (
    <CustomSelect onSelect={handleSelect} {...restProps}>
      {map(
        item => (
          <Select.Option value={item.id} key={item.id}>
            {item.name}
          </Select.Option>
        ),
        items,
      )}
    </CustomSelect>
  )
}

DoctorSelect.fragments = {
  DoctorInDoctorSelect: fragment,
}

export default DoctorSelect
